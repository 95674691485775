import React, { useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import { useSocket } from '../../../hooks/useSocket';
import './styles.scss';

let audio: HTMLAudioElement | undefined;

try {
	audio = new Audio('/sound.mp3')
} catch (error) {

}

export const Queue = () => {

	const { socket } = useSocket('/videoChat');

	const [queueSize, setQueueSize] = useState(0);

	useEffect(() => {
		socket.connect();
		socket.on('queueChange', ({ queueLength }: { queueLength: number }) => {
			setQueueSize(previousSize => {
				if (previousSize < queueLength) {
					audio?.play();
				}
				return queueLength;
			});
		})
		socket.emit('getQueueSize');

		return () => {
			socket.disconnect();
		}
	}, [socket]);

	return <div className="Queue" onClick={() => {
		window.open('https://dev.garantesaude.com.br/atendimento/parceiro', 'Sac garante', 'height=680,width=400');
		// window.open('http://localhost:3000/atendimento/parceiro', 'Sac garante', 'height=680,width=400');
	}}>
		{queueSize === 0 && <p>Não há clientes na fila ;)</p>}
		{[...(new Array(queueSize))].map((_, i) => (
			<div key={i}><FiUser /></div>
		))}
	</div>;
};