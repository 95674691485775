import React, {ButtonHTMLAttributes, useCallback} from 'react';
import './styles.scss';
import {IconBaseProps} from 'react-icons';
import {useForm} from '../../../hooks/useForm';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon? : React.ComponentType<IconBaseProps>;
  color? : 'primary' | 'secondary';
};

export const Button : React.FC<ButtonProps> = ({icon : Icon,  type, onClick, children, color = 'primary', ...rest}) => {

  const {handleSubmit} = useForm();

  const handleClick = useCallback((e : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (type === 'submit') {
      handleSubmit();
    }
    if (onClick) {
      onClick(e);
    }
  }, [handleSubmit, onClick, type]);

  return (
    <button type={type} className={`Button button-${color}`} {...rest} onClick={handleClick}>
      {Icon && <Icon size={16} />}
      {children && <div className="button-content">
        {children}
      </div>}
    </button>
  );
};