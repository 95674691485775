import React, { useCallback } from 'react';
import './styles.scss';
import { Button } from '../../../components/templates/Button';
import { RadioGroup } from '../../../components/templates/RadioGroup';
import { TextArea } from '../../../components/templates/TextArea';
import { Form } from '../../../hooks/useForm';
import { useApi } from '../../../hooks/useApi';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const Review = () => {

	const { api } = useApi();
	const { params } = useRouteMatch<{ id: string }>()
	const history = useHistory();

	const onSubmit = useCallback(async (data: object) => {
		try {
			await api.patch(`/videoChats/addReview/${params.id}`, { review: data });
			history.push('/obrigado');
		} catch (error) {
			console.log(error);
		}
	}, [api, history, params.id]);

	return <div className="review">
		<Form onSubmit={onSubmit}>
			<h2>Avalie seu atendimento</h2>
			<p>Seu proplema foi resolvido neste atendimento?</p>
			<RadioGroup name='problemSolvedRating' type='text' options={[{
				name: 'Sim',
				value: 'Sim'
			},
			{
				name: 'Não',
				value: 'Não'
			},
			{
				name: 'Parcialmente',
				value: 'Parcialmente'
			}
			]} />
			<p>Avalie o atendimento:</p>
			<RadioGroup name='qualityOfAttendance' type='number' options={[{
				name: 'Excelente',
				value: 5
			},
			{
				name: 'Muito bom',
				value: 4
			},
			{
				name: 'Regular',
				value: 3
			},
			{
				name: 'Ruim',
				value: 2
			},
			{
				name: 'Muito ruim',
				value: 1
			}
			]} />
			<p>Nos diga sua forma preferencial de contato: </p>
			<RadioGroup name='preferredFeedback' type='text' options={[{
				name: 'Videochamada',
				value: 'Videochamada'
			},
			{
				name: 'WhatsApp',
				value: 'WhatsApp'
			},
			{
				name: 'Email',
				value: 'Email'
			},
			{
				name: 'Ligação Telefônica',
				value: 'Ligação Telefônica'
			}
			]} />
			<TextArea name='observations' placeholder='Digite observações sobre seu atendimento' />
			<Button type='submit'>Enviar</Button>
		</Form>
	</div>;
};