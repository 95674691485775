import React, { useState, useContext, createContext, useEffect, useCallback } from 'react';
import { useApi } from '../useApi';

interface User {
	id: string;
	name: string;
	email?: string;
}

type SignInFunction = (data: Pick<User, 'email'> & { password: string }) => Promise<void>;

interface AuthContextData {
	signed: boolean;
	user: User;
	signIn: SignInFunction;
	logout: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthContextProvider: React.FC = ({ children }) => {

	const [user, setUser] = useState<User>({} as User);
	const { api } = useApi();

	const signIn: SignInFunction = useCallback(async (data) => {
		try {
			const { data: userData } = await api.post('/sessions', data);
			api.defaults.headers['Authorization'] = userData.token;
			localStorage.setItem('token', userData.token);
			localStorage.setItem('user', JSON.stringify(userData));
			setUser(userData as User)
		} catch (error) {
			console.error(error);
		}
	}, [api]);

	const logout = useCallback(() => {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		setUser({} as User);
	}, []);

	useEffect(() => {
		const verifyToken = async (token: string, userData: User) => {
			try {
				const { data }: { data: { valid: boolean } } = await api.post('/sessions/verifyToken', { token });
				if (data.valid) {
					setUser(userData as User)
				} else {
					logout();
				}
			} catch (error) {
				console.error(error);
			}
		}
		const token = localStorage.getItem('token');
		const userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null;
		if (token && userData) {
			verifyToken(token, userData);
		}
	}, [api, logout]);

	return <AuthContext.Provider value={{ signed: !!user.id, user, signIn, logout }}>
		{children}
	</AuthContext.Provider>
};

export const useAuth = () => {
	const ctx = useContext(AuthContext);
	return ctx
};