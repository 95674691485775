import React, {useRef, useEffect, TextareaHTMLAttributes, useMemo} from 'react';
import './styles.scss';
import {IconBaseProps} from 'react-icons';
import {useForm} from '../../../hooks/useForm';
import {FiAlertCircle} from 'react-icons/fi';
import {ToolTip} from '../ToolTip';

type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'> & {
  name?: string;
  icon? : React.ComponentType<IconBaseProps>;
  size?: 'small' | 'medium' | 'large' | 'fullWidth';
};

export const TextArea : React.FC<TextAreaProps> = ({icon : Icon, size = 'fullWidth', name, ...rest}) => {

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const {registerField, errors} = useForm();
  const error = useMemo(() => errors.find(el => el.name === name), [name, errors]);

  useEffect(() => {
    if (name) {
      registerField({
        name,
        ref: textAreaRef,
        type: 'text'
      });
    }
  }, [name, registerField]);

  return (
    <div className={`TextArea TextArea-${size}`}>
      {Icon && <Icon size={20} />}
      <textarea  autoComplete="off" ref={textAreaRef} name={name} {...rest} />
      {error && <ToolTip error message={error.message}>
        <FiAlertCircle size={20} />
      </ToolTip>}
    </div>
  );
};