import React, {useCallback, useRef, useEffect, useState} from 'react';
import './styles.scss';
import {useForm} from '../../../hooks/useForm';
import {MdRadioButtonUnchecked, MdRadioButtonChecked} from 'react-icons/md';

interface RadioOption {
  name: string;
  value: any;
}

interface RadioGroupProps {
  name?: string;
  type?: string;
  onClick?: (option : RadioOption) => void | Promise<void>;
  options: Array<RadioOption>;
  defaultValue?: any;
  disabled?: boolean;
}

export const RadioGroup : React.FC<RadioGroupProps> = ({type = 'text', defaultValue, options, onClick, name, disabled = false}) => {

  const checkboxRef = useRef<HTMLInputElement>(null);
  const {registerField} = useForm();
  const [checked, setChecked] = useState(() => options.findIndex(el => el.value === defaultValue));

  useEffect(() => {
    if (name) {
      registerField({
        name,
        ref: checkboxRef,
        type
      });
    }
  }, [name, registerField, type]);

  const onRadioOptionClick = useCallback((option : RadioOption) => {
    if (!disabled) {
      if (checkboxRef.current) {
        checkboxRef.current.value = String(option.value);
        setChecked(options.findIndex(el => el.value === option.value));
      }
      if (onClick) {
        onClick(option);
      }
    }
  }, [disabled, onClick, options]);


  return <div className={'RadioButton'}>
    <input ref={checkboxRef} name={name} />
    {options.map((option, i) => (
      <div key={i}>
        { checked !== i ? <MdRadioButtonUnchecked size={28} onClick={() => {
          onRadioOptionClick(option);
          setChecked(i);
        }} />
          : <MdRadioButtonChecked size={28} />
        }
        <p>{option.name}</p>
      </div>
    ))}
  </div>;
};
