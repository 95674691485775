import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSocket } from '../../../hooks/useSocket';
import Peer, { SignalData } from 'simple-peer';
import { Button } from '../../../components/templates/Button';
import { FiCamera, FiCameraOff, FiCheckSquare, FiLogOut, FiMic, FiMicOff } from 'react-icons/fi';
import { useAuth } from '../../../hooks/useAuth';
import { AiFillCloseCircle } from 'react-icons/ai';
import './styles.scss'

let audio: HTMLAudioElement | undefined;

try {
	audio = new Audio('/sound.mp3')
} catch (error) {
	try {
		audio = new Audio('https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3')
	} catch (err){}
}

export const VideoCallAttendant = () => {
	const { socket } = useSocket('/videoChat');
	const { user, logout } = useAuth()
	const [partner, setPartner] = useState<null | string>(null);
	const [enabledMedia, setEnabledMedia] = useState({ audio: true, video: true });
	const [stream, setStream] = useState<MediaStream>();
	const streamRef = useRef(stream);
	const myPeer = useRef<Peer.Instance>();
	const userVideoRef = useRef<HTMLVideoElement>(null);
	const partnerVideoRef = useRef<HTMLVideoElement>(null);
	const [queueSize, setQueueSize] = useState(0);

	useEffect(() => {
		if (user) {
			socket.emit('joinAttendantsTeam', { user });
		}
	}, [socket, user])

	const callClient = useCallback(() => {
		const peer = new Peer({
			initiator: true,
			stream: streamRef.current,
			trickle: false,
			config: {
				iceServers: [
					{ urls: 'stun:stun.l.google.com:19302' },
					{
						urls: 'stun:stun.stunprotocol.org'
					},
					{
						urls: 'turn:numb.viagenie.ca',
						credential: 'muazkh',
						username: 'webrtc@live.com'
					},
				]
			}
		});

		peer.on('signal', (signal: SignalData) => {
			if (signal.type) {
				socket.emit('callClient', { signal });
			}
		})

		peer.on("stream", stream => {
			if (partnerVideoRef.current) {
				partnerVideoRef.current.srcObject = stream;
			}
		});

		peer.on('close', () => {
			if (partnerVideoRef.current) {
				setPartner(null);
			}
		})

		myPeer.current = peer;

	}, [socket]);

	useEffect(() => {

		navigator.mediaDevices?.getUserMedia({ audio: true, video: true }).then(streamData => {

			socket.connect();
			setStream(streamData);
			streamRef.current = streamData;

			if (userVideoRef.current) {
				userVideoRef.current.srcObject = streamRef.current;
			}

			socket.on('partnerDisconnected', () => {
				if (partnerVideoRef.current) {
					setPartner(null);
				}
			})

			socket.on('answerFromClient', ({ signal, client }: { signal: SignalData, client: string }) => {
				myPeer.current?.signal(signal);
				setPartner(client);
			})

			socket.on('queueChange', ({ queueLength }: { queueLength: number }) => {
				setQueueSize(previousSize => {
					if (previousSize < queueLength) {
						audio?.play();
					}
					return queueLength;
				});
			})
			socket.emit('getQueueSize');

		})
	}, [socket]);


	useEffect(() => {
		return () => {
			console.log('ending');
			socket.disconnect();
			myPeer.current?.end();
			if (streamRef.current) {
				for (const track of streamRef.current.getTracks()) {
					track.stop();
				}
			}
		}
	}, [socket])

	return <div className="video-call-attendant">
		<Button className="logoutButton" onClick={() => {
			logout()
		}} icon={FiLogOut}></Button>

		<div className="user-video">
			<video hidden={!partner} playsInline autoPlay ref={partnerVideoRef}></video>
			{partner && <p className="user-name">{partner || ''}</p>}
		</div>

		<div className="user-video">
			<video autoPlay playsInline muted ref={userVideoRef}></video>
			<p className="user-name">{user.name || ''}</p>
			<div className="track-buttons">
				<Button icon={!enabledMedia.audio ? FiMicOff : FiMic} color='primary' onClick={(() => {
					if (streamRef.current) {
						const tracks = streamRef.current?.getTracks();
						tracks[0].enabled = !tracks[0].enabled;
						setEnabledMedia({ audio: tracks[0].enabled, video: tracks[1].enabled });
					}
				})}></Button>
				<Button icon={!enabledMedia.video ? FiCameraOff : FiCamera} onClick={(() => {
					if (streamRef.current) {
						const tracks = streamRef.current?.getTracks();
						tracks[1].enabled = !tracks[1].enabled;
						setEnabledMedia({ audio: tracks[0].enabled, video: tracks[1].enabled });
					}
				})}>
				</Button>
				<Button icon={AiFillCloseCircle} style={{ backgroundColor: 'red' }} onClick={(() => {
					socket.emit('finishCall')
				})}></Button>
			</div>
			{!partner &&
				<div className="panel">
					<h2>Aperte o botão abaixo para responder um ticket de cliente, boa chamada ;)</h2>
					<div>
						<Button icon={FiCheckSquare} onClick={() => {
							callClient();
						}}>Atender Cliente</Button></div>
				</div>
			}
		</div>

	</div>;

}