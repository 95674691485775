import React, {useRef, useContext, createContext, useImperativeHandle, useCallback, useState} from 'react';

interface Field {
	name: string;
	type: string;
	ref: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>;
}

interface FormError {
	name: string;
	message: string;
}

interface FormContextData {
	errors: Array<FormError>;
	registerField: (f: Field) => void;
	handleSubmit: () => void | Promise<void>;
}

interface FormProps {
	onSubmit: (data: {[key: string] : any}) => void | Promise<void>;
	children?: React.ReactNode
}

interface FormHandles {

}

const updateField = (values: any, path: string, value: any) : any => {
  const parts = path.split('.');
  const [key, nestedKey] = parts;
  if (!nestedKey) {
    if (values) {
      return {...(values), [key]:value};
    } else {
      return {[key]: value};
    }
  } else {
    return {...values, [key]: updateField(values[key], parts.slice(1).join('.'), value)};
  }
};

const parseValue = (value: string, type: string) => {
  if (type === 'number') {
    return Number(value);
  }
  if (type === 'boolean') {
    return value === 'true' ? true : false;
  }
  return value;
};

const FormContext = createContext<FormContextData>({} as FormContextData);

export const Form = React.forwardRef<FormHandles, FormProps>(({onSubmit, children}, ref) => {

	const [errors, setErrors] = useState<Array<FormError>>([]);
	const fields = useRef<Array<Field>>([]);

	const registerField = useCallback((field : Field) => {
		fields.current.push(field);
	}, []);

	const handleSubmit = useCallback(async () => {
    let submitData : {[key: string] : any} = {};
    for (const field of fields.current) {
      if (field.ref.current && field.ref.current.value) {
        const value = parseValue(field.ref.current.value, field.type);
        submitData = updateField(submitData, field.name, value);
      }
    }

    if (onSubmit) {
      await onSubmit(submitData);
    }

  }, [onSubmit]);

	return <FormContext.Provider value={{handleSubmit, registerField, errors}}>
		<div className="Form">
			{children}
		</div>
	</FormContext.Provider>;
});

export const useForm = () => {
	const ctx = useContext(FormContext);
	return ctx;
};

