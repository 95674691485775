import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSocket } from '../../../hooks/useSocket';
import Peer, { SignalData, SimplePeer } from 'simple-peer';
import { Button } from '../../../components/templates/Button';
import { FiCamera, FiCameraOff, FiMic, FiMicOff } from 'react-icons/fi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '../../../hooks/useForm';
import { Input } from '../../../components/templates/Input';
import queryString from 'query-string';
import './styles.scss';
interface IUser {
	name: string;
	email: string;
}

export const VideoCallClient = () => {
	const { socket } = useSocket('/videoChat');
	const [user, setUser] = useState<IUser | null>(null);
	const peerRef = useRef<Peer.Instance | null>(null);
	const [partner, setPartner] = useState<null | string>(null);
	const [enabledMedia, setEnabledMedia] = useState({ audio: true, video: true });
	const [stream, setStream] = useState<MediaStream>();
	const streamRef = useRef(stream);
	const myPeer = useRef<Peer.Instance>();
	const location = useLocation();
	const userVideoRef = useRef<HTMLVideoElement>(null);
	const partnerVideoRef = useRef<HTMLVideoElement>(null);
	const history = useHistory();
	const attempts = useRef(3);

	const queryParams = queryString.parse(location.search) as { name: string, email: string };

	const createPeer = useCallback((signal: SignalData, attendant: string) => {
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: streamRef.current,
			config: {
				iceServers: [
					{ urls: 'stun:stun.l.google.com:19302' },
					{
						urls: 'stun:stun.stunprotocol.org'
					},
					{
						urls: 'turn:numb.viagenie.ca',
						credential: 'muazkh',
						username: 'webrtc@live.com'
					},
				]
			}
		});

		myPeer.current = peer;

		peer.on("signal", data => {
			if (signal.type) {
				socket.emit("returnCall", { signal: data });
			}
		})

		peer.on("stream", data => {
			if (partnerVideoRef.current) {
				partnerVideoRef.current.srcObject = data;
				setPartner(attendant);
			}
		});

		peer.on('error', () => {
			console.log('error, recreating');
			if (partnerVideoRef.current) {
				setPartner(null);
			}
			if (attempts.current >= 0) {
				attempts.current--;
				createPeer(signal, attendant);
			}
		})

		peer.on('close', () => {
			if (partnerVideoRef.current) {
				setPartner(null);
			}
		})

		peer.signal(signal);

	}, [socket]);

	const initialize = useCallback(() => {
		navigator.mediaDevices?.getUserMedia({ audio: true, video: true }).then(streamData => {

			socket.connect();
			setStream(streamData);
			streamRef.current = streamData;

			if (userVideoRef.current) {
				userVideoRef.current.srcObject = streamRef.current;
			}

			socket.on('callingYou', ({ signal, attendant }: { signal: SignalData, attendant: string }) => {
				createPeer(signal, attendant);
			})

			socket.on('partnerDisconnected', ({ chatId }: { chatId: string }) => {
				peerRef.current?.end()

				console.log("partner out")
				socket.disconnect();

				if (partnerVideoRef.current) {
					setPartner(null);
				}
				history.push(`/review/${chatId}`);
			})
		})
	}, [socket, history, createPeer]);

	useEffect(() => {
		if (queryParams.name && queryParams.name.length > 0) {
			setUser({ name: queryParams.name, email: '' });

			socket.emit('joinQueue', { user: { name: queryParams.name, email: '' } });
			initialize();
		}
	}, [initialize, queryParams.name, setUser, socket])


	useEffect(() => {
		return () => {
			console.log('ending');
			socket.disconnect();
			myPeer.current?.end();
			if (streamRef.current) {
				for (const track of streamRef.current.getTracks()) {
					track.stop();
				}
			}
		}
	}, [socket])

	return <>
		{!user ? <div className="video-call-client"><Form onSubmit={(data: any) => {
			setUser(data);
			socket.emit('joinQueue', { user: data });
			initialize();
		}}>
			<h2>Bem vindo a central de atendimento Garante Benefícios! Como deseja ser chamado? :)</h2>
			<Input type='text' placeholder='Nome' name='name' />
			<Button type='submit'>Entrar</Button>
		</Form>
		</div>
			:
			<div className="video-call-client">
				<div className="user-video">
					<video hidden={!partner} playsInline autoPlay ref={partnerVideoRef}></video>
					{partner && <p className="user-name">{partner || ''}</p>}
				</div>
				<div className="user-video">
					<video autoPlay playsInline muted ref={userVideoRef}></video>
					<p className="user-name">{user.name || ''}</p>
					<div className="track-buttons">
						<Button icon={!enabledMedia.audio ? FiMicOff : FiMic} color='primary' onClick={(() => {
							if (streamRef.current) {
								const tracks = streamRef.current?.getTracks();
								tracks[0].enabled = !tracks[0].enabled;
								setEnabledMedia({ audio: tracks[0].enabled, video: tracks[1].enabled });
							}
						})}></Button>
						<Button icon={!enabledMedia.video ? FiCameraOff : FiCamera} onClick={(() => {
							if (streamRef.current) {
								const tracks = streamRef.current?.getTracks();
								tracks[1].enabled = !tracks[1].enabled;
								setEnabledMedia({ audio: tracks[0].enabled, video: tracks[1].enabled });
							}
						})}>
						</Button>
						<Button icon={AiFillCloseCircle} style={{ backgroundColor: 'red' }} onClick={(() => {
							socket.emit('finishCall')
						})}></Button>
					</div>
					{!partner &&
						<div className="panel">
							<p>Olá <span>{user.name}</span>, aguarde um momento, você será atendido em instantes...
							<br />
							Nosso horário de atendimento: <b>Segunda a Sexta das 09:00 as 18:00hrs.</b></p>
						</div>
					}
				</div>
			</div>}</>;
}