import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthOrVideoCall } from '../AppPages/AuthOrVideoCall';
import { Queue } from '../AppPages/Queue';
import { Review } from '../AppPages/Review';
import { ThankYou } from '../AppPages/ThankYou';
import { VideoCallClient } from '../AppPages/VideoCallClient';

export const Routes = () => {
	return <Switch>
		<Route exact path='/' component={() => <VideoCallClient />} />
		<Route exact path='/atendimento/parceiro' component={() => <AuthOrVideoCall />} />
		<Route exact path='/fila' component={Queue} />
		<Route exact path='/review/:id' component={Review} />
		<Route exact path='/obrigado' component={ThankYou} />
	</Switch>;
};