import React from 'react';
import { Button } from '../../../components/templates/Button';
import { Input } from '../../../components/templates/Input';
import './styles.scss';
import { AuthContextProvider, useAuth } from '../../../hooks/useAuth';
import { Form } from '../../../hooks/useForm';
import { VideoCallAttendant } from '../VideoCallAttendant';

const AuthOrVideoCallConsumer = () => {
	const { signed, signIn } = useAuth();

	if (signed) {
		return <VideoCallAttendant />
	}


	return <div className="auth">
		<Form onSubmit={(data: any) => {
			signIn({ ...data, type: 'attendant' });
		}}>
			<h2>Bem vindo parceiro Garante, desejamos um excelente dia de trabalho :) </h2>
			<Input type='text' placeholder='Email' name='email' />
			<Input type='password' placeholder='Senha' name='password' />
			<Button type='submit'>Entrar</Button>
		</Form>
	</div>;

}

export const AuthOrVideoCall: React.FC = () => {
	return <AuthContextProvider>
		<AuthOrVideoCallConsumer />
	</AuthContextProvider>
}