import React from 'react';
import './styles.scss';

interface ToolTipProps {
  message: string;
  error?: boolean;
}

export const ToolTip : React.FC<ToolTipProps> = ({message, children, error = false}) => {
  return <div className={`ToolTip ${error && 'toolTip-error'}`}>
    <div>{message}</div>
    {children}
  </div>;
};