import React, {InputHTMLAttributes, useRef, useEffect, useMemo, useState} from 'react';
import './styles.scss';
import {IconBaseProps} from 'react-icons';
import {useForm} from '../../../hooks/useForm';
import {ToolTip} from '../ToolTip';
import {FiAlertCircle} from 'react-icons/fi';

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
  name?: string;
  icon? : React.ComponentType<IconBaseProps>;
  size?: 'small' | 'medium' | 'large' | 'fullWidth';
};

export const Input : React.FC<InputProps> = ({icon : Icon, disabled, type = 'text', size = 'fullWidth', name, ...rest}) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const {registerField, errors} = useForm();
  const [focused, setFocused] = useState(false);
  const error = useMemo(() => errors.find(el => el.name === name), [name, errors]);

  useEffect(() => {
    if (name) {
      registerField({
        name,
        ref: inputRef,
        type
      });
    }
  }, [name, registerField, type]);

  return (
    <div className={`Input input-${size} ${focused && 'input-focused'} ${disabled && 'input-disabled'}`}>
      {Icon && <Icon size={20} />}
      <input disabled={disabled} type={type} autoComplete="off"  ref={inputRef} name={name} {...rest} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
      {error && <ToolTip error message={error.message}>
        <FiAlertCircle size={20} />
      </ToolTip>}
    </div>
  );
};
